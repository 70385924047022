import { Address, CodeableConcept, Coding, ServiceRequest } from "fhir"
import { SYSTEM_VALUES } from "system-values"

const STRIPE_SYSTEM = "http://chartedhealth.com/fhir/payment/stripe"

enum ContactPointSystem {
  phone = "phone",
  fax = "fax",
  email = "email",
  pager = "pager",
  url = "URL",
  sms = "SMS",
  other = "other",
}
enum IDENTIFIER_CODE {
  DEA = "DEA",
  NPI = "NPI",
  AN = "AN",
  LIFEFILE_PRACTICE = "lifefile-practice",
  LIFEFILE_PRACTITIONER = "lifefile-practitioner",
  HEALTH_GORILLA = "healthgorilla",
}

const identifiersCodeByType = {
  practitioner: [IDENTIFIER_CODE.AN, IDENTIFIER_CODE.NPI],
  practitionerRole: [IDENTIFIER_CODE.DEA, IDENTIFIER_CODE.LIFEFILE_PRACTITIONER],
}

const formatsByTypes: { [key in FormatTypes]: string } = {
  ISO_8601_DATE: "yyyy-MM-dd",
  ISO_8601_DATETIME: "yyyy-MM-dd'T'HH:mm:ss",
  SHORT_DATE: "MMM do",
  SHORT_DATETIME: "MMM do, h:mm a",
  SHORT_DATETIME_W_SEC: "MMM do, h:mm:ss a",
  LONG_DATE: "MMM do, yyyy",
  LONG_DATETIME: "MMM do, yyyy h:mm a",
  FULL_DATETIME: "MMMM do, yyyy h:mm a",
  TIME: "h:mm a",
  SHORT_MONTH_YEAR: "MMM yyyy",
  LONG_MONTH_YEAR: "MMMM yyyy",
  LONG_DATETIME_WITH_TIMEZONE: "MMM do, yyyy h:mm a z",
  SHORT_DATETIME_WITH_TIMEZONE: "MMM do, h:mm a z",
  FULL_DATETIME_WITH_TIMEZONE: "MMMM do, yyyy h:mm a z",
}
export enum PRACTITIONER_ROLE {
  ALL = "all",
  ADMIN = "admin",
  NON_ADMIN = "non-admin",
  PRACTITIONER = "practitioner",
}

const genders = [
  { code: "female", label: "Female" },
  { code: "male", label: "Male" },
]
const countries = [{ label: "United States", code: "US" }]

export type FormatTypes =
  | "ISO_8601_DATE"
  | "ISO_8601_DATETIME"
  | "SHORT_DATE"
  | "SHORT_DATETIME"
  | "LONG_DATE"
  | "LONG_DATETIME"
  | "FULL_DATETIME"
  | "TIME"
  | "SHORT_DATETIME_W_SEC"
  | "LONG_MONTH_YEAR"
  | "SHORT_MONTH_YEAR"
  | "LONG_DATETIME_WITH_TIMEZONE"
  | "SHORT_DATETIME_WITH_TIMEZONE"
  | "FULL_DATETIME_WITH_TIMEZONE"

enum ORGANIZATION_TYPE {
  HEALTHCARE_PROVIDER = "healthcare-provider",
  MANUFACTURER = "manufacturer",
  MEDICATION_DISTRIBUTOR = "medication-distributor",
  DIAGNOSTIC_LABORATORIES = "diagnostic-laboratories",
  INSURANCE_PROVIDER = "insurance-provider",
  FACILITY_PROVIDER = "facility-provider",
}
const organizationTypes: OrganizationType[] = [
  {
    code: "healthcare-provider",
    display: "Healthcare Provider",
    system: "http://chartedhealth.com/fhir/organization-type",
  },
  {
    code: "manufacturer",
    display: "Manufacturer",
    system: "http://chartedhealth.com/fhir/organization-type",
  },
  {
    code: "medication-distributor",
    display: "Medication Distributor",
    system: "http://chartedhealth.com/fhir/organization-type",
  },
  {
    code: "diagnostic-laboratories",
    display: "Diagnostic Laboratories",
    system: "http://chartedhealth.com/fhir/organization-type",
  },
  {
    code: "insurance-provider",
    display: "Insurance Provider",
    system: "http://chartedhealth.com/fhir/organization-type",
  },
  {
    code: "facility-provider",
    display: "Facility Provider",
    system: "http://chartedhealth.com/fhir/organization-type",
  },
]

const srCategoryCodes: Record<"lab-order" | "pharmaceutical-order" | "nutraceutical-order", Coding> = {
  "lab-order": {
    code: "lab-order",
    system: "http://chartedhealth.com/fhir/service-request-type",
    display: "Lab Order",
  },
  "pharmaceutical-order": {
    code: "pharmaceutical-order",
    system: "http://chartedhealth.com/fhir/service-request-type",
    display: "Pharmaceutical Order",
  },
  "nutraceutical-order": {
    code: "nutraceutical-order",
    system: "http://chartedhealth.com/fhir/service-request-type",
    display: "Nutraceutical Order",
  },
}

const isLabOrder = (order: ServiceRequest) =>
  order.category?.some((cc) => cc.coding?.some((c) => c.code === srCategoryCodes["lab-order"].code)) ?? false

const isMedicationOrder = (order: ServiceRequest) =>
  order.category?.some((cc) => cc.coding?.some((c) => c.code === "medication-order")) ?? false

const isRxOrder = (order: ServiceRequest) =>
  order.orderDetail?.some((cc) => cc.coding?.some((c) => c.code === "medication-order")) ?? false

const organizationExtraTypes: Record<"support-auto-shipping-free", CodeableConcept> = {
  "support-auto-shipping-free": {
    text: "Support Auto Shipping Free",
    coding: [
      {
        code: "support-auto-shipping-free",
        system: "http://chartedhealth.com/fhir/organization-type",
        display: "Support Auto Shipping Free",
      },
    ],
  },
}

const requestCommonsStatusCodes = [
  { code: "draft", display: "Draft" },
  { code: "on-hold", display: "On hold" },
  { code: "revoked", display: "Cancelled" },
  { code: "completed", display: "Completed" },
]

const requestStatusCodes = [{ code: "active", display: "Active" }]

const laboratoryOrderStatusCodes = [
  { code: "requisition-pending", display: "Requisition Pending" },
  { code: "requisition-available", display: "Requisition Available" },
  { code: "preliminary-results", display: "Preliminary Results" },
  { code: "final-results-available", display: "Final Results Available" },
]

const subsNotificationType: SubsNotificationType[] = [
  {
    code: "success",
    display: "Success",
  },
  {
    code: "failed",
    display: "Failed",
  },
]

const emptyAddress: Address = {
  country: "US",
  line: ["", ""],
  city: "",
  state: "",
  postalCode: "",
  use: "home",
}

type OrganizationType = {
  code: string
  display: string
  system: string
}

type SubsNotificationType = {
  code: string
  display: string
}

export type ResourceStatusCode = {
  reason?: string
  day?: number
  statusCode: string
  b2cUserId: string | null
}
const READER_DEVICE_IDENTIFIER_SYSTEM = "http://chartedhealth.com/fhir/reader/stripe"

export enum PAYMENT_METHODS {
  CARD_READER = "card-reader",
  CREDIT_CARD = "credit-card",
}
const organizationSettingId: Record<
  | "ehrModules"
  | "branding"
  | "defaultOrgdata"
  | "medicationsCatalogs"
  | "paymentMethods"
  | "labFacilities"
  | "consents",
  string
> = {
  ehrModules: "ehr-modules",
  branding: "branding",
  defaultOrgdata: "default-org-data",
  medicationsCatalogs: "medications-catalogs",
  paymentMethods: "payment-methods",
  labFacilities: "order-labs",
  consents: "consents",
}

const dayOfWeek = [
  { code: "mon", label: "Monday" },
  { code: "tue", label: "Tuesday" },
  { code: "wed", label: "Wednesday" },
  { code: "thu", label: "Thursday" },
  { code: "fri", label: "Friday" },
  { code: "sat", label: "Saturday" },
  { code: "sun", label: "Sunday" },
]

const ORDER_STATUS_SYSTEM = "http://chartedhealth.com/fhir/order-status"

export enum MEDICATION_PRODUCT_TYPE {
  RX = "medication",
  NUTRA = "nutraceutical",
}
export enum MEDICATION_CATALOG {
  RX = "pharmacy",
  NUTRA = "medication-distributor",
}

export type OrgDataSettingCode = "defaultProcedureIcd10"

const defaultOrgDataSettingCodes: Record<OrgDataSettingCode, string> = {
  defaultProcedureIcd10: "default-procedure-icd10",
}

const azureContainer = {
  photos: "practitioner-photos",
  drs: "diagnostic-reports",
  branding: "branding-images",
  docs: "document-reference",
}

export const catalogVisibility: Record<"patientVisibility" | "practiceVisibility", CodeableConcept> = {
  patientVisibility: {
    text: "Catalog Visibility Patient",
    coding: [
      {
        code: "catalog-visibility-patient",
        system: SYSTEM_VALUES.SETTING_DEFINITION_FLAG,
        display: "Catalog Visibility Patient",
      },
    ],
  },
  practiceVisibility: {
    text: "Catalog Visibility Practice",
    coding: [
      {
        code: "catalog-visibility-practice",
        system: SYSTEM_VALUES.SETTING_DEFINITION_FLAG,
        display: "Catalog Visibility Practice",
      },
    ],
  },
}

const taskPriorityCodes = [
  {
    code: "routine",
    display: "Routine",
  },
  {
    code: "urgent",
    display: "Urgent",
  },
  {
    code: "asap",
    display: "ASAP",
  },
  {
    code: "stat",
    display: "STAT",
  },
]
const invoiceStatusCodes = [
  { code: "draft", display: "Draft" },
  { code: "issued", display: "Issued" },
  { code: "balanced", display: "Paid" },
  { code: "cancelled", display: "Cancelled" },
  { code: "entered-in-error", display: "Entered in error" },
]

enum BILLING_TYPES_CODES {
  BILL_PATIENT = "bill-patient",
  BILL_PRACTICE = "bill-practice",
  INSURANCE = "insurance",
}

const LAB_REFERENCE_ID_CODE = "ACSN"

export {
  azureContainer,
  BILLING_TYPES_CODES,
  ContactPointSystem,
  countries,
  dayOfWeek,
  defaultOrgDataSettingCodes,
  emptyAddress,
  formatsByTypes,
  genders,
  IDENTIFIER_CODE,
  identifiersCodeByType,
  invoiceStatusCodes,
  isLabOrder,
  isMedicationOrder,
  isRxOrder,
  LAB_REFERENCE_ID_CODE,
  laboratoryOrderStatusCodes,
  ORDER_STATUS_SYSTEM,
  ORGANIZATION_TYPE,
  organizationExtraTypes,
  organizationSettingId,
  organizationTypes,
  READER_DEVICE_IDENTIFIER_SYSTEM,
  requestCommonsStatusCodes,
  requestStatusCodes,
  srCategoryCodes,
  STRIPE_SYSTEM,
  subsNotificationType,
  taskPriorityCodes,
}
