import { faBars, faBuilding } from "@fortawesome/pro-light-svg-icons"
import {
  faCalendarCheck,
  faCalendarDays,
  faExternalLink,
  faFileInvoiceDollar,
  faPills,
  faSearch,
  faTrashCan,
  faUser,
  faVials,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, parseISO } from "date-fns"
import { Patient, Reference, ServiceRequest, humanNameAsString } from "fhir"
import pluralize from "pluralize"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { FC, useCallback, useEffect, useId, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  Button,
  EmptyMessage,
  InfiniteScroll,
  MenuStyles,
  SearchInput,
  SearchWithFilters,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useScreenContext,
} from "commons"
import { formatsByTypes, isLabOrder, isMedicationOrder, isRxOrder } from "data"
import { useOrganization } from "organizations"
import { usePatient } from "patients"
import { useLoginContext } from "security"
import { formatDate, getBadgeColor, getServiceRequestBillingType, strCapitalize } from "utils"

import { useCancelMrOrder, useOrganizationOrders, useOrganizationOrdersAndSettings, useRevokeLabOrder } from "../hooks"
import { OrderFilter } from "../types"
import {
  getCleanDate,
  getCleanType,
  getLabOrderIdentifier,
  getLabOrderStatus,
  getOrderIdentifierText,
  getStatusesByType,
  getStatusesClean,
} from "../utils"
import { OrdersFiltersFormContiner } from "./OrdersFiltersFormContainer"

const NUTRA_VIEW = "nutraceuticals"
const RX_VIEW = "eprescribe"
const DEFAULT_KP_VIEW = "kp=patient-information"
const LAB_VIEW = "labs"

const OrdersView: FC = () => {
  const loaderKey = useId()
  const [params, setParams] = useSearchParams()
  const searchText = params.get("search") ?? undefined
  const type = getCleanType(params.get("type") ?? "") ?? undefined
  const organizationId = params.get("organization") ?? undefined
  const patientId = params.get("patientId") ?? undefined
  const status = getStatusesClean(params.get("status")?.split(",") ?? [], getStatusesByType(type ?? "")) ?? undefined
  const authored = params.get("authored") ? getCleanDate(params.get("authored") ?? "") : undefined
  const occurrence = params.get("occurrence") ? getCleanDate(params.get("occurrence") ?? "") : undefined
  const [patient, setPatient] = useState<Reference>()
  const [organization, setOrganization] = useState<Reference>()
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const { isRootAdmin } = useLoginContext()

  useEffect(() => {
    status?.length ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    !type && params.delete("type")
    !authored && params.delete("authored")
    !occurrence && params.delete("occurrence")
    setParams(params)
  }, [])

  const { serviceRequests, patients, count, total, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useOrganizationOrders(
      organizationId,
      type,
      patientId,
      undefined,
      status?.join(","),
      authored,
      occurrence,
      searchText,
    )
  const { orgUrlHash } = useOrganizationOrdersAndSettings(
    organizationId,
    type,
    patientId,
    undefined,
    status?.join(","),
    authored,
    occurrence,
  )
  const { patient: patientData, isLoading: isLoadingPatient } = usePatient(patientId, !patient?.display)
  const { organization: org, isLoading: isLoadingOrg } = useOrganization(organizationId)

  useEffect(() => {
    if (!isLoadingPatient) {
      if (patientId && !patient?.display && patientData?.name?.[0]) {
        setPatient({ id: patientId, display: humanNameAsString(patientData?.name?.[0]) })
      } else if (!patient) {
        params.delete("patientId")
        setPatient(undefined)
        setParams(params)
      }
    }
  }, [patient?.display, patientData?.name, patientId, isLoadingPatient])

  useEffect(() => {
    if (!isLoadingOrg) {
      if (organizationId && !organization?.display && org?.organization.name) {
        setOrganization({ id: organizationId, display: org?.organization.name })
      } else if (!organization) {
        params.delete("organization")
        setOrganization(undefined)
        setParams(params)
      }
    }
  }, [organization?.display, org?.organization.name, organizationId, isLoadingOrg])

  const setFilters = ({ searchText, type, patient, organization, status, authored, occurrence }: OrderFilter) => {
    searchText ? params.set("search", encodeURI(searchText)) : params.delete("search")
    type ? params.set("type", type) : params.delete("type")
    patient?.id ? params.set("patientId", patient?.id) : params.delete("patientId")
    organization?.id ? params.set("organization", organization.id) : params.delete("organization")
    status?.length ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    authored
      ? params.set("authored", formatDate(authored, formatsByTypes.ISO_8601_DATE) ?? "")
      : params.delete("authored")
    occurrence
      ? params.set("occurrence", formatDate(occurrence, formatsByTypes.ISO_8601_DATE) ?? "")
      : params.delete("occurrence")
    setPatient(patient)
    setOrganization(organization)
    setParams(params)
  }
  const navigate = (domain: string) => {
    window.open(domain, "_blank", "noopener")
  }
  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const [cancelReason, setCancelReason] = useState("")
  const [orderToCancel, setOrderToCancel] = useState<ServiceRequest>()

  const closeCancelModal = () => {
    setOrderToCancel(undefined)
  }

  const { cancelMrOrder, isCancelling } = useCancelMrOrder(undefined, closeCancelModal)
  const { revokeLabOrder, isRevoking } = useRevokeLabOrder(undefined, closeCancelModal)

  const cancelOrder = () => {
    if (isMedicationOrder(orderToCancel as ServiceRequest))
      cancelMrOrder({
        order: orderToCancel as ServiceRequest,
        cancelReason,
      })
    else revokeLabOrder({ order: orderToCancel as ServiceRequest, revokeReason: cancelReason })
  }

  const showOrder = useCallback(
    (order: ServiceRequest) => {
      const orgId = patients.filter((p) => p.id === order.subject.id)?.[0].managingOrganization?.id
      const cleanUrl = (str: string) => str.replace(/([^:]\/)\/+/g, "$1")
      let url = `${orgUrlHash[orgId as string]}/orgs/${orgId}/patients/${order.subject.id}`

      if (isMedicationOrder(order)) {
        const view = isRxOrder(order) ? RX_VIEW : NUTRA_VIEW

        url = cleanUrl(
          `${url}?${DEFAULT_KP_VIEW}&view=${view}&subview=${order?.status === "active" ? "orders" : "history"}&order=${
            order?.id
          }`,
        )
      } else if (isLabOrder(order)) {
        url = cleanUrl(`${url}?${DEFAULT_KP_VIEW}&view=${LAB_VIEW}&order=${order?.id}`)
      }

      navigate(url)
    },
    [orgUrlHash, patients],
  )

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <div className="px-6 pt-6 border-b drop-shadow">
        <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
          {isSmallScreen ? (
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
              onClick={() => setSidebarCollapsed(false)}
            />
          ) : null}
          <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
            <h6 className="font-medium text-lg">Orders</h6>
            <p className="text-sm text-gray-500">
              Showing {count} {pluralize("order", count)} of {total} found
            </p>
          </div>
        </div>

        <div className="flex py-4 w-full justify-between">
          <div className="card flex justify-content-center">
            <SearchWithFilters
              showOverlaypanel={overlayFilter}
              showSidebar={overlaySidebarFilter}
              setShowSidebar={setOverlaySidebarFilter}
              filterNone={!type && !patient && !status && !organization && !authored && !occurrence}
              showSearchInput={false}
              customSearchInput={
                <SearchInput
                  className="flex-1"
                  search={(searchText) => {
                    setFilters({
                      searchText: searchText,
                      type,
                      patient,
                      organization,
                      status,
                      authored,
                      occurrence,
                    })
                  }}
                  isLoading={isLoading}
                  autoFocus={false}
                  initialValue={searchText}
                />
              }
              isLoading={isLoading || isFetchingNextPage}
              formContent={
                <OrdersFiltersFormContiner
                  initialValues={{
                    type,
                    patient,
                    organization,
                    searchText,
                    status,
                    authored,
                    occurrence,
                  }}
                  onSearch={(filters) => {
                    setFilters(filters)
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                  onClearFilters={() => {
                    setFilters({})
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
      {isLoading || isLoadingOrg || isLoadingPatient ? (
        loader()
      ) : !serviceRequests.length ? (
        <EmptyMessage icon={faSearch} message="No Orders Found" subMessage={false} />
      ) : (
        <div className="flex flex-col overflow-auto h-full">
          <InfiniteScroll useWindow={false} hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <StackedListContainer
              itemPadding
              data={serviceRequests}
              itemModelBuilder={(item) =>
                modelBuilder(
                  item,
                  patients,
                  () => showOrder(item),
                  isRootAdmin
                    ? () => {
                        setOrderToCancel(item)
                        setCancelReason("")
                      }
                    : undefined,
                )
              }
            />
          </InfiniteScroll>
          <Dialog
            header={`Cancel order ${getOrderIdentifierText(orderToCancel)}`}
            visible={!!orderToCancel || isCancelling || isRevoking}
            draggable={false}
            style={{ width: "35vw" }}
            onHide={closeCancelModal}
            footer={
              <div className="mt-2">
                <Button
                  label="Cancel order"
                  className="button-primary"
                  disabled={!cancelReason}
                  loading={isCancelling || isRevoking}
                  onClick={cancelOrder}
                />
              </div>
            }
          >
            <label>Please provide a reason</label>
            <InputText className="w-full mt-2" value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
          </Dialog>
        </div>
      )}
    </>
  )
}

const modelBuilder = (
  order: ServiceRequest,
  patients: Patient[],
  showOrder: () => void,
  cancelOrder?: () => void,
): StackedListItemProps => {
  const itemsCount =
    order.basedOn?.filter((ref) =>
      isMedicationOrder(order) ? ref.resourceType === "MedicationRequest" : ref.resourceType === "ServiceRequest",
    ) ?? []
  const isMedicationRequest = isMedicationOrder(order)
  const orderStatus = isMedicationRequest ? order.status : getLabOrderStatus(order)?.display

  const org = patients.filter((p) => p.id === order.subject.id)?.[0].managingOrganization?.display
  const orderBillingTypeDisplay = strCapitalize(getServiceRequestBillingType(order).replace("bill-", ""))

  const data = isMedicationOrder(order)
    ? [
        {
          lineItems: [
            {
              name: "Order number",
              value: order.identifier?.[0]?.value ?? "Unspecified number",
            },
          ],
        },
        {
          lineItems: [
            { name: "Patient", value: order.subject.display, icon: faUser },
            { name: "Bill to", value: orderBillingTypeDisplay, icon: faFileInvoiceDollar },
            {
              name: "Occurrence",
              value: format(
                new Date(order?.occurrence?.dateTime ?? (order?.authoredOn as string)),
                formatsByTypes.LONG_DATE,
              ),
              icon: faCalendarCheck,
            },
            { name: "Medication Requests", value: `${itemsCount.length}`, icon: faPills },
            { name: "Organization", value: org, icon: faBuilding },
          ],
        },
      ]
    : [
        {
          lineItems: [
            {
              name: "Laboratory",
              value: `${strCapitalize(order.performer?.[0]?.display as string)} (${orderStatus !== "draft" ? `${getLabOrderIdentifier(order, [])}` : ""})`,
            },
          ],
        },
        {
          lineItems: [
            { name: "Patient", value: order.subject.display, icon: faUser },
            { name: "Bill to", value: orderBillingTypeDisplay, icon: faFileInvoiceDollar },
            {
              name: "Authored on",
              value: format(parseISO(order.authoredOn as string), formatsByTypes.LONG_DATE),
              icon: faCalendarDays,
            },
            { name: "Tests", value: `${itemsCount.length}`, icon: faVials },
            ...(order.occurrence?.dateTime
              ? [
                  {
                    name: "Occurrence",
                    icon: faCalendarCheck,
                    value: format(parseISO(order.occurrence?.dateTime as string), formatsByTypes.LONG_DATE),
                  },
                ]
              : []),
            { name: "Organization", value: org, icon: faBuilding },
          ],
        },
      ]

  return {
    leftData: data,
    menu: [
      { command: showOrder, label: "Show order", icon: <FontAwesomeIcon icon={faExternalLink} className="fa-fw" /> },
      ...(cancelOrder
        ? [
            {
              command: cancelOrder,
              label: "Cancel order",
              icon: <FontAwesomeIcon icon={faTrashCan} className="fa-fw" />,
              disabled: ["draft", "on-hold"].includes(order.status) || orderStatus?.toLowerCase() === "cancelled",
            },
          ]
        : []),
    ],
    menuStyle: cancelOrder ? MenuStyles.Dropdown : MenuStyles.ExternalAction,
    badge: getBadgeColor(orderStatus?.toLowerCase() ?? "unspecified"),
  }
}

export { OrdersView }
