import { Sidebar } from "primereact/sidebar"
import { useState } from "react"

import {
  Button,
  EmailCheckContext,
  EmailCheckProvider,
  FormContainer,
  LoadingView,
  PractitionerApi,
  useScreenContext,
} from "commons"
import { ReplaceFormProvider } from "commons/context"

import { useActiveDeactivePractitioner, useCreatePractitioner, usePractitioner, useUpdatePractitioner } from "../hooks"
import { PractitionerForm } from "./PractitionerForm"
import { PractitionerList } from "./PractitionerList"
import { APIPractitionerValidationSchema, initialValues, sanitize } from "./validations"

const PractitionerContainer = () => {
  const [showOverlayForm, setShowOverlayForm] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [practitionerId, setPractitionerId] = useState<string | undefined>()
  const { practitioner, isLoading } = usePractitioner(practitionerId)
  const { isSmallScreen } = useScreenContext()

  const closeForm = () => {
    setPractitionerId(undefined)
    setShowOverlayForm(false)
    setIsNew(true)
  }
  const edit = (practitioner: PractitionerApi) => {
    setIsNew(false)
    setPractitionerId(practitioner.practitioner?.id)
    setShowOverlayForm(true)
  }

  const { createPractitioner, isAdding } = useCreatePractitioner(closeForm)
  const { updatePractitioner, isUpdating } = useUpdatePractitioner(closeForm)
  const { updatePractitionerStatus } = useActiveDeactivePractitioner()

  const customSidebarTitlebar = (
    <>
      <span className="bg-white w-full">
        <h6 className="font-semibold"> {isNew ? "Add" : " Edit"} Practitioner</h6>
        <p className="text-slate-400 text-sm">Use a permanent address where you can receive email</p>
      </span>
    </>
  )
  const changeStatus = () => {
    updatePractitionerStatus(practitioner)
    setPractitionerId(undefined)
  }

  const onSubmit = (practitioner: PractitionerApi) => {
    const pract = sanitize(practitioner)

    !practitioner.practitioner?.id ? createPractitioner(pract) : updatePractitioner(pract)
  }

  return (
    <>
      <PractitionerList
        showForm={() => setShowOverlayForm(true)}
        edit={edit}
        changeStatus={changeStatus}
        setPractitionerId={setPractitionerId}
      />
      <Sidebar
        visible={showOverlayForm}
        position={isSmallScreen ? "bottom" : "right"}
        className={isSmallScreen ? "h-[95%] rounded-t-xl" : "md:w-1/2 lg:w-1/3"}
        header={customSidebarTitlebar}
        onHide={closeForm}
      >
        {isLoading ? (
          <LoadingView />
        ) : (
          <div className="relative h-full w-full">
            <ReplaceFormProvider>
              <EmailCheckProvider>
                <FormContainer
                  initialValue={initialValues(practitioner)}
                  onSubmit={onSubmit}
                  onCancel={closeForm}
                  enableReinitialize
                  validationSchema={APIPractitionerValidationSchema("practitioner")}
                  customSaveButton={
                    <EmailCheckContext.Consumer>
                      {({ isCheckingEmailInUse }) => (
                        <Button
                          label="Save"
                          disabled={isCheckingEmailInUse}
                          loading={isAdding || isUpdating}
                          type="submit"
                        />
                      )}
                    </EmailCheckContext.Consumer>
                  }
                >
                  <PractitionerForm initialValues={initialValues(practitioner)} />
                </FormContainer>
              </EmailCheckProvider>
            </ReplaceFormProvider>
          </div>
        )}
      </Sidebar>
    </>
  )
}

export { PractitionerContainer }
