import { Organization } from "fhir"
import { Sidebar } from "primereact/sidebar"
import { useState } from "react"

import { FormContainer, LoadingView, useScreenContext } from "commons"
import { ReplaceFormProvider } from "commons/context"
import { useLoginContext } from "security"

import { useCreateOrganization, useOrganizationsPartOf, useUpdateOrganization } from "../hooks"
import { OrganizationForm } from "./OrganizationForm"
import { OrganizationtList } from "./OrganizationList"
import { initialValues, organizationValidationSchema, sanitize } from "./validations"

const OrganizationListView = () => {
  const { logedInPractitioner } = useLoginContext()
  const { organizationsRoots, isLoading } = useOrganizationsPartOf(logedInPractitioner?.practitioner.id)
  const [showOverlayForm, setShowOverlayForm] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [organization, setOrganization] = useState<Organization>()
  const { isSmallScreen } = useScreenContext()

  const closeForm = () => {
    setShowOverlayForm(false)
  }

  const edit = (organization: Organization) => {
    setIsNew(false)
    setOrganization(organization)
    setShowOverlayForm(true)
  }

  const newOrganization = () => {
    setIsNew(true)
    setShowOverlayForm(true)
    setOrganization(undefined)
  }

  const { createOrganization } = useCreateOrganization(closeForm)
  const { updateOrganization } = useUpdateOrganization(closeForm)

  const customSidebarTitle = (
    <span className="bg-white w-full">
      <h6 className="font-semibold">{isNew ? "Add" : "Edit"} Organization</h6>
      <p className="text-slate-400 text-sm">Use a permanent address where you can receive emails</p>
    </span>
  )

  const onSubmit = (organization: Organization) => {
    isNew ? createOrganization(sanitize(organization)) : updateOrganization(sanitize(organization))
  }

  return (
    <>
      <OrganizationtList newOrganization={newOrganization} edit={edit} />
      <Sidebar
        visible={showOverlayForm}
        position={isSmallScreen ? "bottom" : "right"}
        style={isSmallScreen ? { height: "95%" } : { minWidth: "30%" }}
        header={customSidebarTitle}
        onHide={closeForm}
      >
        {isLoading ? (
          <LoadingView />
        ) : (
          <ReplaceFormProvider>
            <div className="relative h-full w-full">
              <FormContainer
                initialValue={initialValues(organization)}
                onSubmit={onSubmit}
                onCancel={closeForm}
                validationSchema={organizationValidationSchema}
              >
                <OrganizationForm organizationsRoots={organizationsRoots} isEditing={!isNew} />
              </FormContainer>
            </div>
          </ReplaceFormProvider>
        )}
      </Sidebar>
    </>
  )
}

export { OrganizationListView }
