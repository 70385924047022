import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CodeableConcept, Coding, Practitioner, PractitionerRole } from "fhir"
import { useFormikContext } from "formik"
import { FC, useCallback, useState } from "react"

import {
  AutoCompleteReferenceField,
  CodeableConceptField,
  FieldWithClearInputAction,
  ReferenceDropdownField,
  ValueSetIds,
} from "commons"
import { IdentifierContainer } from "identifier"
import { useOrganizationRefs } from "organizations"
import { usePractitionersOrganization } from "practitioners"
import { useValueSet } from "value-set"

const PractitionerRoleForm: FC<Props> = ({
  fieldPath,
  error,
  practitioner,
  existentRoleCodesByOrg,
  prIdentifierTypes,
}) => {
  const { values: newPractitionerRole, setFieldValue } = useFormikContext<PractitionerRole>()
  const { codes } = useValueSet({ valueSetId: ValueSetIds.PRACTITIONER_ROLE, enabled: true })

  const [filter, setFilter] = useState("")

  const { organizationRefs, isLoading, isFetching } = useOrganizationRefs(filter)
  const { practitionerRolesRefs, isLoading: isLoadingPractInfo } = usePractitionersOrganization({
    organizationId: newPractitionerRole?.organization?.id as string,
    isNotPractitionerId: practitioner?.id,
  })
  const roleValidation = (value: CodeableConcept) => (!value?.coding?.[0]?.code ? "Code is required" : null)

  const customPath = fieldPath ? `${fieldPath}.` : ""

  const isRoleUnselectable = useCallback(
    (code: Coding) => {
      const codesForSelectedOrg = newPractitionerRole?.organization?.id
        ? existentRoleCodesByOrg?.[newPractitionerRole?.organization?.id]
        : undefined

      return codesForSelectedOrg?.includes(code.code as string) ?? false
    },
    [newPractitionerRole?.organization?.id],
  )

  return (
    <div className="space-y-5 w-full">
      <AutoCompleteReferenceField
        field={`${customPath}organization`}
        label="Organization"
        setFilter={setFilter}
        optionList={organizationRefs}
        isLoading={isLoading || isFetching}
        disabled={!!newPractitionerRole?.id}
        validation={(organization) => (!organization ? "Organization is required" : null)}
      />
      {!!error && (
        <div className="flex  flex-row items-start h-2 mt-1 text-sm">
          <FontAwesomeIcon icon={faCircleExclamation} className="p-error mt-1" />
          <span className="p-error ml-1 ">This practitioner role has been already added</span>
        </div>
      )}

      <CodeableConceptField
        label="Code"
        field={`${customPath}code[0]`}
        codes={codes ?? []}
        optionDisabled={isRoleUnselectable}
        validation={roleValidation}
        disabled={!newPractitionerRole?.organization?.id}
      />

      <FieldWithClearInputAction
        clearAction={() => setFieldValue(`${customPath}relatedPractitioner`, undefined)}
        showClear={!!newPractitionerRole?.relatedPractitioner?.[0]?.practitioner.id}
      >
        <ReferenceDropdownField
          field={`${customPath}relatedPractitioner[0].practitioner`}
          label="Supervisor Physician"
          options={practitionerRolesRefs ?? []}
          optionLabel="display"
          isLoading={isLoadingPractInfo}
          className="w-full"
        />
      </FieldWithClearInputAction>

      <IdentifierContainer
        field={`${customPath}identifier`}
        label="Identifiers"
        avoidReplacement
        types={prIdentifierTypes}
      />
    </div>
  )
}

type Props = {
  fieldPath?: string
  existentRoleCodesByOrg?: Record<string, string[]>
  error?: boolean
  practitioner?: Practitioner
  prIdentifierTypes:
    | {
        code: string | undefined
        system: string | undefined
        display: string | undefined
      }[]
    | undefined
}

export { PractitionerRoleForm }
