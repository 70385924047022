import { useMutation, useQueryClient } from "@tanstack/react-query"
import { List, Parameters, ServiceRequest } from "fhir"

import { IClient, useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { SYSTEM_VALUES } from "system-values"
import { displayNotificationSuccess } from "utils"

import { ordersQueryKeys } from "../query-keys"

const useCancelMrOrder = (onSuccess?: () => void, onSettled?: () => void) => {
  const { operationRequest } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()

  const cancelOrder = ({ order, cancelReason }: { order: ServiceRequest; cancelReason: string }) => {
    const list: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: [
        {
          item: {
            resourceType: "ServiceRequest",
            id: order.id,
          },
          flag: {
            coding: [
              {
                code: "skip",
                system: SYSTEM_VALUES.ORDER_CANCEL_MODE,
              },
            ],
          },
        },
      ],
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "orders",
          resource: list,
        },
        {
          name: "reason",
          value: {
            string: cancelReason,
          },
        },
      ],
    }

    return operationRequest(`Patient/${order.subject.id}/cpoe`, "POST", "cancel", undefined, parameters)
  }

  const { mutate: cancelMrOrder, isPending } = useMutation({
    mutationFn: cancelOrder,
    onError: (error: CustomError, { order }) => {
      displayNotificationError(registerErrorTrace(error, { id: order?.id }))
    },
    onSuccess: async (_, { order }) => {
      await queryClient.invalidateQueries({
        queryKey: ordersQueryKeys.all,
        refetchType: "all",
      })
      displayNotificationSuccess("Order cancelled successfully")
      datadogLogs.logger.info(`Order with id ${order.id} cancelled successfully`)
      onSuccess?.()
    },
    onSettled,
  })

  return { cancelMrOrder, isCancelling: isPending }
}

export { useCancelMrOrder }
