import {
  AddressField,
  BirthdateField,
  EmailField,
  GenderField,
  InputField,
  PractitionerApi,
  ValueSetIds,
} from "commons"
import { IDENTIFIER_CODE, identifiersCodeByType } from "data"
import { IdentifierContainer } from "identifier"
import { PractitionerRoleContainer } from "practitioner-role"
import { useValueSet } from "value-set"

const PractitionerForm = ({ initialValues, showBirthDate = true }: Props) => {
  const { codes } = useValueSet({ valueSetId: ValueSetIds.PRACTITIONER_IDENTIFIERS, enabled: true })
  const practIdentifierTypes = codes?.filter((code) =>
    identifiersCodeByType.practitioner.includes(code.code as IDENTIFIER_CODE),
  )
  const prIdentifierTypes = codes?.filter((code) =>
    identifiersCodeByType.practitionerRole.includes(code.code as IDENTIFIER_CODE),
  )

  return (
    <div className="w-full">
      <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
        <legend>Basic Information</legend>
        <InputField field="practitioner.name[0].given[0]" label="First Name" />
        <InputField field="practitioner.name[0].given[1]" label="Middle Name" />
        <InputField field="practitioner.name[0].family" label="Last Name" className="col-span-2" />

        <InputField field="practitioner.name[0].prefix[0]" label="Prefix" />
        <InputField field="practitioner.name[0].suffix[0]" label="Sufix" />
      </fieldset>

      <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
        <GenderField field="practitioner.gender" label="Biological Sex" className="col-span-2" />
        {showBirthDate && <BirthdateField field="practitioner.birthDate" label="Birthdate" className="col-span-2" />}
      </fieldset>

      <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
        <legend>Address</legend>
        <AddressField context="PractitionerApi" parentFieldName="practitioner.address[0]" />
      </fieldset>

      <fieldset className="grid gap-5 p-3 mb-5">
        <legend>Telecom</legend>

        <EmailField
          field="practitioner.telecom[0].value"
          label="Email"
          initialValue={initialValues.practitioner?.telecom?.[0].value}
          validateDuplicate={true}
          isPractitionerRole
        />

        <InputField
          type="tel"
          mask="+1 (999) 999-9999"
          unmask={true}
          field="practitioner.telecom[1].value"
          label="Phone"
        />
      </fieldset>
      <fieldset className="grid gap-5 p-3 mt-5">
        <PractitionerRoleContainer
          field="roles"
          label="Roles"
          prIdentifierTypes={prIdentifierTypes}
          isPractActive={initialValues.practitioner.active ?? true}
        />
      </fieldset>
      <fieldset className="grid gap-5 p-3 mt-5">
        <IdentifierContainer field="practitioner.identifier" label="Identifiers" types={practIdentifierTypes} />
      </fieldset>
    </div>
  )
}

type Props = {
  showBirthDate?: boolean
  initialValues: PractitionerApi
}

export { PractitionerForm }
