import { faSearch, faStethoscope } from "@fortawesome/pro-regular-svg-icons"
import { faBuilding, faCancel, faCheck, faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString, PractitionerRole } from "fhir"
import { useFormikContext } from "formik"
import { Tooltip } from "primereact/tooltip"
import { useCallback, useEffect, useMemo } from "react"
import { v4 } from "uuid"

import {
  AddFieldArrayItemButton,
  ConfirmDialog,
  FormField,
  PractitionerApi,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
  useReplaceFormContext,
} from "commons"
import { SYSTEM_VALUES } from "system-values"
import { getBadgeColor } from "utils"

import { PractitionerRoleForm } from "./PractitionerRoleForm"
import { INITIAL_VALUES, practitionerRoleValidation } from "./validation"

const PractitionerRoleContainer = ({ field, label, isPractActive, prIdentifierTypes }: Props) => {
  const {
    setFieldValue,
    values: { roles, practitioner },
  } = useFormikContext<PractitionerApi>()

  const {
    showSlide,
    initialValue,
    deleteIndex,
    editIndex,
    editWithIndex,
    add: onAdd,
    reset,
    setDeleteIndex,
  } = useCrudReducer({
    defaultEntity: { ...INITIAL_VALUES },
  })

  const formReplaceContext = useReplaceFormContext<PractitionerRole>()

  const onReset = () => {
    reset()
    formReplaceContext?.setShowReplacementContent?.(false)
  }

  const onSubmit = useCallback(
    (data: PractitionerRole) => {
      const newPractRole = { ...data, ...getAdditionalProps(data) }
      setFieldValue(field, [
        ...(editIndex !== undefined
          ? roles?.toSpliced(editIndex, 1, newPractRole) ?? []
          : [...(roles ?? []), newPractRole]),
      ])
      onReset()
    },
    [editIndex, roles],
  )

  const currentRolesCodesByOrg = useMemo(
    () =>
      roles?.reduce(
        (acc, { code, organization }) => {
          return organization?.id && code
            ? { ...acc, [organization.id]: [...(acc[organization.id] ?? []), code[0]?.coding?.[0]?.code as string] }
            : acc
        },
        {} as Record<string, string[]>,
      ),
    [roles],
  )

  const replacementFormProps = useMemo(() => {
    return {
      title: label,
      showForm: showSlide,
      useFormik: true,
      initialValue,
      validationSchema: practitionerRoleValidation,
      saveLabel: initialValue.id ? "Save" : "Add",
      children: (
        <PractitionerRoleForm
          existentRoleCodesByOrg={currentRolesCodesByOrg}
          practitioner={practitioner}
          prIdentifierTypes={prIdentifierTypes}
        />
      ),
      showCloseIcon: false,
      onCancel: onReset,
      onSubmit: onSubmit,
    }
  }, [showSlide, initialValue, currentRolesCodesByOrg, onSubmit])

  useEffect(() => {
    if (showSlide) formReplaceContext?.setReplacementContent?.(replacementFormProps)
    else formReplaceContext?.setShowReplacementContent?.(false)
  }, [replacementFormProps])

  const itemModel = (
    pr: PractitionerRole,
    index: number,
    setDeleteIndex: (index: number) => void,
  ): StackedListItemProps => ({
    leftData: [
      {
        lineItems: [
          {
            name: "Code",
            value: codeableConceptAsString(pr.code?.[0]),
          },
        ],
      },
      {
        lineItems: [
          {
            name: "Organization",
            value: pr.organization?.display ?? "No organization",
            icon: faBuilding,
          },
          ...(pr.relatedPractitioner?.some(
            (rp) =>
              rp.relationType.code === "supervising-practitioner" &&
              rp.relationType.system === SYSTEM_VALUES.RELATED_PRACTITIONER,
          )
            ? [
                {
                  name: "Supervising Physician",
                  value: pr.relatedPractitioner.find((rp) => rp.relationType.code === "supervising-practitioner")
                    ?.practitioner.display,
                  icon: faStethoscope,
                },
              ]
            : []),
        ],
      },
      ...(pr.identifier?.length
        ? [
            {
              withVerticalDivider: true,
              lineItems: pr.identifier
                ?.filter(({ type }) => !!type)
                ?.map(({ type, value }) => ({
                  name: codeableConceptAsString(type),
                  value: `${codeableConceptAsString(type)}: ${value}`,
                })),
            },
          ]
        : []),
    ],
    badge: getBadgeColor(pr.active ? "active" : "deactivated"),
    menu: [
      {
        label: "Edit",
        icon: (
          <>
            {pr.active === false && <Tooltip content="Activate role before editing" target=".edit" position="left" />}
            <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />
          </>
        ),
        disabled: pr.active === false,
        className: "edit",
        command: () => {
          const practRole = roles?.[index]
          if (practRole) {
            editWithIndex(practRole, index)
          }
        },
      },
      {
        label: pr.active ? "Deactivate" : "Activate",
        icon: (
          <>
            {isPractActive === false && (
              <Tooltip content="Active practitioner is needed" target=".active" position="left" />
            )}
            <FontAwesomeIcon icon={pr.active ? faCancel : faCheck} size="sm" className="mr-2" />
          </>
        ),
        disabled: !isPractActive,
        className: "active",
        command: () => {
          if (roles?.[index]) {
            setFieldValue(`roles[${index}].active`, !roles[index].active)
          }
        },
      },
      {
        label: "Delete",
        disabled: !!pr.id,
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
        command: () => setDeleteIndex(index),
      },
    ],
  })

  const deletePractRole = useCallback(
    (index: number) => {
      const updatedRoles = roles?.toSpliced(index, 1)
      setFieldValue(field, updatedRoles)
    },
    [roles],
  )

  return (
    <FormField field={field} label={label} className="w-full @container" showInvalidState>
      <AddFieldArrayItemButton className="px-2 py-4" onClick={onAdd} />
      {roles?.length ? (
        <StackedListContainer
          itemsClassName="px-2 py-4"
          data={roles}
          itemModelBuilder={(item, index) => itemModel(item, index, setDeleteIndex)}
        />
      ) : (
        <div className="flex flex-col items-center justify-center py-5">
          <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
          <p className="text-slate-500 text-xs pt-1">No {label.toLowerCase()} added yet</p>
        </div>
      )}

      <ConfirmDialog
        confirmText="Are you sure you want to remove this role?"
        actionName="Remove"
        visible={deleteIndex !== undefined}
        onConfirm={() => deletePractRole(deleteIndex as number)}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </FormField>
  )
}

const getAdditionalProps = (pr: PractitionerRole) => {
  if (pr.relatedPractitioner?.[0]?.practitioner.id) {
    pr.relatedPractitioner[0].relationType = {
      system: SYSTEM_VALUES.RELATED_PRACTITIONER,
      code: "supervising-practitioner",
      display: "Supervisor Physician",
    }
  }
  if (!pr.id) {
    pr.id = `new-${v4()}`
  }

  return { ...pr }
}

type Props = {
  field: string
  label: string
  isPractActive: boolean
  prIdentifierTypes:
    | {
        code: string | undefined
        system: string | undefined
        display: string | undefined
      }[]
    | undefined
}

export { PractitionerRoleContainer }
