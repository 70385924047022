import { type Coding } from "fhir"

const medicineClassificationCodings = [
  { code: "mental-wellness", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Mental wellness" },
  { code: "non-sterile", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Non sterile" },
  { code: "peptides", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Peptides" },
  {
    code: "sterile",
    system: "http://chartedhealth.com/fhir/therapeutic-class",
    display: "Sterile",
  },
] as Coding[]

export { medicineClassificationCodings }
