import { faBars, faPlus, faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { humanNameAsString } from "fhir"
import { Divider } from "primereact/divider"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { FC, useId, useMemo, useReducer, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

import {
  Button,
  ConfirmDialog,
  DialogFormContainer,
  GroupedList,
  InputField,
  PractitionerApi,
  ReferenceDropdownField,
  SearchWithFilters,
  SkeletonLoader,
  StackedListItem,
  useScreenContext,
} from "commons"
import { IDENTIFIER_CODE } from "data"

import { useDiagnosticLaboratories } from "organizations"
import { useInvitePractitioner, usePractitionerLabSetup, usePractitioners } from "../hooks"
import { FilterProps } from "../types"
import { PractitionerEmptyState } from "./PractitionerEmptyState"
import { PractitionerFilters } from "./PractitionerFilters"
import { PractitionerInvite } from "./PractitionerInvite"
import { practModelBuilder } from "./practModelBuilder"
import { LabSetupValidationSchema } from "./validations"

const PractitionerList: FC<Props> = ({ showForm, edit, setPractitionerId, changeStatus, isChangingStatus }) => {
  const { searchText, gender, email, organization, role, status, search, filter, reset } = useReducerState()
  const [showInviteDialog, setShowInviteDialog] = useState(false)
  const [showActiveDeactiveDialog, setShowActiveDeactiveDialog] = useState(false)
  const [showLabSetupDialog, setShowLabSetupDialog] = useState(false)
  const [practitioner, setPractitioner] = useState<PractitionerApi | undefined>(undefined)
  const practitionerLabsAlreadyConfigure = useMemo(
    () => practitioner?.practitioner?.identifier?.filter((i) => i.type?.coding?.[0].code === IDENTIFIER_CODE.AN),
    [practitioner],
  )
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const { practitionerLabSetup, isPending } = usePractitionerLabSetup(practitioner?.practitioner.id ?? "", () =>
    setShowLabSetupDialog(false),
  )
  const { diagnosticLabs } = useDiagnosticLaboratories("")

  const { practitionerGroups, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } =
    usePractitioners(searchText, email, gender, organization, role, status)

  const { invitePractitioner, isInviting } = useInvitePractitioner(
    practitioner?.practitioner?.id as string,
    humanNameAsString(practitioner?.practitioner?.name?.[0]),
    () => {
      setShowInviteDialog(false)
    },
  )

  const handleActiveDeactivePractitioner = (pract: PractitionerApi) => {
    setPractitionerId(pract.practitioner.id as string)
    setShowActiveDeactiveDialog(true)
  }
  const handlePractitionerInvite = (pract: PractitionerApi) => {
    setPractitioner(pract)
    setShowInviteDialog(true)
  }
  const handlePractitionerLabSetup = (pract: PractitionerApi) => {
    setPractitioner(pract)
    setShowLabSetupDialog(true)
  }
  const hideInviteDialog = () => {
    setShowInviteDialog(false)
    setPractitioner(undefined)
  }
  const hideActiveDeactiveDialog = () => {
    setShowActiveDeactiveDialog(false)
    setPractitioner(undefined)
  }
  const hidePractitionerLabSetup = () => {
    setPractitioner(undefined)
    setShowLabSetupDialog(false)
  }
  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  return (
    <div className="pt-6 bg-white h-full flex flex-col">
      <div className="px-6 border-b shadow-sm">
        <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
          {isSmallScreen ? (
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
              onClick={() => setSidebarCollapsed(false)}
            />
          ) : null}
          <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
            <h6 className="font-medium text-lg">Practitioners</h6>
            <p className="text-sm text-gray-500">
              Showing {count} practitioners of {total} found
            </p>
          </div>
        </div>

        <div className="flex py-4 w-full justify-between">
          <div className="flex mr-2">
            <SearchWithFilters
              showOverlaypanel={overlayFilter}
              showSidebar={overlaySidebarFilter}
              setShowSidebar={setOverlaySidebarFilter}
              onTextFilter={search}
              isLoading={isLoading || isFetchingNextPage}
              filterNone={!email && !gender && !organization && !role && !status}
              formContent={
                <PractitionerFilters
                  initialValues={{ gender, email, organization, role, status }}
                  onSearch={(filters) => {
                    filter(filters)
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                  onClearFilters={() => {
                    reset()
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                />
              }
            />
          </div>

          <Button
            label={isSmallScreen ? "Add" : "Add practitioner"}
            icon={isSmallScreen ? faPlus : undefined}
            className="button-primary "
            onClick={showForm}
          />
        </div>
      </div>

      {isLoading ? (
        loader()
      ) : !practitionerGroups?.length ? (
        <PractitionerEmptyState onAdd={showForm} />
      ) : (
        <div className="flex flex-col overflow-auto grow">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
            <GroupedList
              className="grow"
              groups={practitionerGroups}
              renderDivider={(name) => (
                <div className="bg-gray-50 px-6 py-1 border-t border-b first:border-t-0">
                  <p className="text-sm text-gray-500 font-medium capitalize">{name}</p>
                </div>
              )}
              renderItem={(pract) => (
                <>
                  <StackedListItem
                    itemPadding
                    modelData={practModelBuilder(
                      pract ?? {},
                      edit,
                      handleActiveDeactivePractitioner,
                      handlePractitionerInvite,
                      handlePractitionerLabSetup,
                    )}
                  />
                </>
              )}
              renderEmptyState={() => (
                <div className="flex flex-col items-center justify-center pt-10">
                  <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                  <p className="text-slate-400 pt-3">No results found, please change filters and try again</p>
                </div>
              )}
            />
          </InfiniteScroll>
          {
            <ConfirmDialog
              visible={showActiveDeactiveDialog || isChangingStatus}
              onConfirm={() => changeStatus(practitioner)}
              hideDialog={hideActiveDeactiveDialog}
              isLoading={isChangingStatus}
              confirmText={`Do you want ${
                practitioner?.practitioner?.active === false ? "activate" : "deactivate"
              } this practitioner`}
            />
          }
          {showInviteDialog && (
            <PractitionerInvite
              showInviteDialog={showInviteDialog}
              practitioner={practitioner?.practitioner ?? {}}
              isInviting={isInviting}
              hideInviteDialog={hideInviteDialog}
              invitePractitioner={invitePractitioner}
            />
          )}
          {showLabSetupDialog && (
            <DialogFormContainer
              title="Laboratory setup"
              showForm={showLabSetupDialog || isPending}
              onCancel={hidePractitionerLabSetup}
              onSubmit={(data) => practitionerLabSetup(data ?? {})}
              initialValue={{ value: "", organization: undefined }}
              validationSchema={LabSetupValidationSchema}
            >
              <div className="w-full pb-3">
                {!!practitionerLabsAlreadyConfigure?.length && (
                  <section className="flex flex-col mb-3">
                    <h3 className="text-gray-700 font-medium mb-1">Fully configured labs:</h3>
                    <ul>
                      {practitionerLabsAlreadyConfigure.map((i, index) => (
                        <li className="text-sm text-gray-400" key={i.id ?? index}>
                          <span title="Assigner">{i.assigner?.display ?? "Unspecified laboratory"}</span> -{" "}
                          <span title="Account number">{i.value ?? "Unspecified account number"}</span>
                        </li>
                      ))}
                    </ul>
                    <Divider />
                  </section>
                )}
                <InputField field="value" label="Account number" />
                <ReferenceDropdownField field="organization" label="Organization" options={diagnosticLabs} />
              </div>
            </DialogFormContainer>
          )}
        </div>
      )}
    </div>
  )
}

const initialState = {
  searchText: "",
  email: "",
  gender: "",
  organization: undefined,
  role: "",
  status: "",
} as FilterProps

const reducer = (
  state: FilterProps,
  { type, payload }: { type: "reset" | "search" | "filter"; payload?: string | FilterProps },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as FilterProps) }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, email, gender, organization, role, status }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: FilterProps) => {
    dispatch({ type: "filter", payload: filters })
  }

  return { searchText, email, gender, organization, role, status, filter, search, reset }
}

type Props = {
  showForm(): void
  edit(pract: PractitionerApi): void
  setPractitionerId(practitionerId: string): void
  changeStatus(pract?: PractitionerApi): void
  isChangingStatus?: boolean
}

export { PractitionerList }
